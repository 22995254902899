// Insert a reload button next to the captcha question to change it without reloading the page
window.addEventListener("DOMContentLoaded", function () {
  // Label - we'll inject the reload button here
  const labelEl = document.querySelector(".user_humanizer_answer label");
  if (!labelEl) {
    console.log("No label for the captcha question found");
    return;
  }

  // Hidden input for the question ID - we'll tell the API to return a question that's different from this one
  const questionIdInputEl = document.querySelector(
    ".user_humanizer_question_id input[type=hidden]"
  );
  if (!questionIdInputEl) {
    console.log("No input for the captcha question ID found");
    return;
  }

  // Reload Button
  const reloadButtonEl = document.createElement("a");
  reloadButtonEl.href = "#";
  reloadButtonEl.classList.add("captcha-reload-button");

  // New question span element
  const question = labelEl.textContent;
  const questionEl = document.createElement("span");
  questionEl.textContent = question;
  labelEl.textContent = "";

  // Replace the label with question + reload button
  labelEl.appendChild(questionEl);
  labelEl.appendChild(reloadButtonEl);

  // Reload Button click
  reloadButtonEl.addEventListener("click", function (event) {
    event.preventDefault();

    const params = {
      current_question_id: questionIdInputEl.value,
    };
    const csrfParam = document.querySelector('meta[name="csrf-param"]').content;
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    params[csrfParam] = csrfToken;

    fetch("/captcha/change_question", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => {
        // Update the label with the new question and store its ID
        questionEl.textContent = data.question;
        questionIdInputEl.value = data.question_id;
      })
      .catch((error) =>
        console.error("Error fetching new captcha question:", error)
      );
  });
});
